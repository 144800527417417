import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import {
  selectMouthImage,
  selectSelectedLanguage,
  selectTextContent,
  setImageFromDevice,
  setNewMouthImage,
} from './questionnaireSlice';
import {ReactComponent as CameraMask} from '../../assets/svg/camera_mask.svg';
import IconButtonSquared from '../../components/IconButtonSquared';
import BigButton from '../../components/BigButton';

const WebcamResult = () => {
  const dispatch = useDispatch();
  const {trackEvent} = useMatomo();
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const resultImageSrc = useSelector(selectMouthImage);
  const [cameraOpen, setCameraOpen] = React.useState(false);
  const [seconds, setSeconds] = React.useState(5);
  const [timerOn, setTimerOn] = React.useState(false);
  const webcamRef = React.useRef(null);

  const fileInputRef = useRef(null);

  const videoConstraints = {
    width: {min: 720},
    height: {min: 720},
    aspectRatio: 1,
  };

  const capture = React.useCallback(() => {
    if (webcamRef && webcamRef.current) {
      // @ts-ignore
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        if (getCookieConsentValue()) {
          trackEvent({category: 'camera', action: 'Retake photo'});
        }
        dispatch(setNewMouthImage(imageSrc));
        setCameraOpen(false);
        setSeconds(5);
        setTimerOn(false);
      }
    }
  }, [webcamRef, dispatch, trackEvent]);

  useEffect(() => {
    if (timerOn && seconds > 0) {
      let timer1 = setTimeout(() => setSeconds(seconds - 1), 1000);

      return () => {
        clearTimeout(timer1);
      };
    } else {
      capture();
    }
  }, [seconds, timerOn, capture]);

  const retakeButtonPressed = () => {
    setCameraOpen(true);
    if (getCookieConsentValue()) {
      trackEvent({category: 'camera', action: 'Retake photo camera opened'});
    }
  };

  return (
    <WebcamResultContainer>
      {resultImageSrc && !cameraOpen ? (
        <>
          <ResultImage src={resultImageSrc || ''} alt="result image" />
          <IconButtonSquared
            text={
              textContent.questions_general_header_contents?.form_page_retake_photo_button.translations[
                selectedLanguage
              ].text
            }
            onClick={() => retakeButtonPressed()}
          />
        </>
      ) : null}
      {cameraOpen ? (
        <>
          <WebcamContainer>
            <StyledCameraMask width="100%" height="100%" />
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              width="100%"
              audio={false}
              mirrored={true}
            />
          </WebcamContainer>
          <IconButtonSquared
            text={
              timerOn
                ? `${
                    textContent.questions_general_header_contents?.pre_questionnaire_page_timer_text.translations[
                      selectedLanguage
                    ].text
                  } ${seconds.toString()}`
                : `${textContent.questions_general_header_contents?.pre_questionnaire_page_start_timer_button.translations[selectedLanguage].text}`
            }
            onClick={() => setTimerOn(true)}
          />
        </>
      ) : null}
      {/* Always show the 'Choose from device' button */}
      <br />
      <label style={{}}>
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          style={{display: 'none'}}
          ref={fileInputRef}
          onChange={(e: any) => {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
              dispatch(setNewMouthImage(reader.result as string));
              dispatch(setImageFromDevice(true));
            };
            reader.onerror = function (error) {
              console.error('Error: ', error);
            };
          }}
        />
        <BigButton
          text={
            textContent.questions_general_header_contents?.pre_questionnaire_page_choose_from_device_button
              .translations[selectedLanguage].text
          }
          onClick={() => {
            if (fileInputRef && fileInputRef.current) {
              setCameraOpen(false);
              setSeconds(5);
              setTimerOn(false);
              (fileInputRef.current as any).click();
            }
          }}
        />
      </label>
    </WebcamResultContainer>
  );
};

const WebcamResultContainer = styled.div`
  margin: 10px 0;
`;
const WebcamContainer = styled.div`
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  margin: 10px 0;
`;
const StyledCameraMask = styled(CameraMask)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;

const ResultImage = styled.img`
  border-radius: 18px;
  width: 100%;
`;

export default WebcamResult;
