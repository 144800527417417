import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/colors';
import {BodyTextWhite} from '../styles/typography';

interface TextBubbleProps {
  text: string;
}

const TextBubble = (props: TextBubbleProps) => {
  return (
    <StyledTextBubble>
      <BodyTextWhite>{props.text}</BodyTextWhite>
    </StyledTextBubble>
  );
};

const StyledTextBubble = styled.div`
  background: ${colors.textBubble.background};
  border-radius: 18px;
  padding: 12px 18px;
  border: 0;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export default TextBubble;
