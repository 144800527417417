import React from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {colors} from '../../styles/colors';
import {selectSelectedLanguage} from './questionnaireSlice';
import {useSelector} from 'react-redux';

interface QuestionnaireSliderProps {
  question: Question;
  selectedValue: number | null;
  onValueChange: (value: number) => void;
}

const QuestionnaireSlider = (props: QuestionnaireSliderProps) => {
  const {question, selectedValue} = props;
  const selectedLang = useSelector(selectSelectedLanguage);

  return (
    <SliderContainer>
      <SliderTitlesContainer>
        <SliderTitle>{question.translations[selectedLang].text_left}</SliderTitle>
        <SliderTitle>{question.translations[selectedLang].text_right}</SliderTitle>
      </SliderTitlesContainer>
      <Slider min={-3} max={3} value={selectedValue || 0} onChange={(value: number) => props.onValueChange(value)} />
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${colors.white};
  border-radius: 12px;
  padding: 20px;
`;
const SliderTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`;
const SliderTitle = styled.span`
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${colors.text.blue};
`;

export default QuestionnaireSlider;
