import styled from 'styled-components';
import {colors} from './colors';

export const Title = styled.h1`
  font-family: hoss-round, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.015em;
  color: ${colors.text.blue};
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const SmallTitle = styled.h2`
  font-family: hoss-round, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 34px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.015em;
  color: ${colors.text.blue};
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const SmallTitleLeft = styled(SmallTitle)`
  text-align: left;
`;

export const BoldText = styled.p`
  font-family: hoss-round, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: ${colors.text.blue};
  margin-block-start: 0;
  margin-block-end: 0;
  white-space: pre-line;
`;

export const BodyText = styled.p`
  font-family: hoss-round, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${colors.text.blue};
  margin-block-start: 0;
  margin-block-end: 0;
  white-space: pre-line;
`;

export const BodyTextWhite = styled(BodyText)`
  color: ${colors.text.white};
  white-space: pre-line;
`;

export const ButtonTextBold = styled.span`
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: ${colors.button.text};
`;

export const ButtonText = styled.span`
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: ${colors.button.text};
`;
