import React from 'react';
import styled from 'styled-components';
import {colors} from '../styles/colors';
import {ButtonTextBold} from '../styles/typography';

interface BigButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const BigButton = (props: BigButtonProps) => {
  return (
    <StyledBigButton onClick={props.onClick} disabled={props.disabled || false} type="button">
      <ButtonTextBold>{props.text}</ButtonTextBold>
    </StyledBigButton>
  );
};

const StyledBigButton = styled.button<{disabled: boolean}>`
  padding: 24px 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  background: ${(props) => (props.disabled ? colors.button.disabledBackground : colors.button.background)};
  width: 100%;
  border: 0;
  :hover {
    background: ${(props) => (props.disabled ? colors.button.disabledBackground : colors.button.hoverBackground)};
  }
`;

export default BigButton;
