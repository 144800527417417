import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {fieldsApi} from '../services/fields';
import questionnaireReducer from '../features/questionnaire/questionnaireSlice';

export const store = configureStore({
  reducer: {
    [fieldsApi.reducerPath]: fieldsApi.reducer,
    questionnaire: questionnaireReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(fieldsApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
