import React from 'react';
import styled from 'styled-components';
import {ReactComponent as SmallLogo} from '../assets/svg/smilefix_logo_small.svg';

const HeaderLogo = () => {
  return (
    <HeaderLogoContainer>
      <SmallLogo />
    </HeaderLogoContainer>
  );
};

const HeaderLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 15px;
`;

export default HeaderLogo;
