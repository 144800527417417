import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import Breadcrumbs from '../components/Breadcrumbs';
import ContainerBox from '../components/ContainerBox';
import BackgroundContainer from '../components/BackgroundContainer';
import TextBubble from '../components/TextBubble';
import {
  selectAppUrl,
  selectDentistUuid,
  selectSelectedLanguage,
  selectTextContent,
} from '../features/questionnaire/questionnaireSlice';
import BigButton from '../components/BigButton';
import HeaderLogo from '../components/HeaderLogo';
import {ReactComponent as Logo} from '../assets/svg/smilefix_logo.svg';
import {Link} from 'react-router-dom';

function getShareLinkURL(dentistUuid: string | null): string {
  const dentistQueryParam = `?dentist=${dentistUuid}`;
  const REACT_APP_PUBLIC_URL = process.env.PUBLIC_URL;

  if (REACT_APP_PUBLIC_URL) {
    return REACT_APP_PUBLIC_URL.endsWith('/')
      ? `${REACT_APP_PUBLIC_URL}${dentistQueryParam}`
      : `${REACT_APP_PUBLIC_URL}/${dentistQueryParam}`;
  } else {
    const REACT_APP_BASE_URL_DEV = process.env.REACT_APP_BASE_URL_DEV;
    return REACT_APP_BASE_URL_DEV?.endsWith('/')
      ? `${REACT_APP_BASE_URL_DEV}${dentistQueryParam}`
      : `${REACT_APP_BASE_URL_DEV}/${dentistQueryParam}`;
  }
}

function Summary() {
  const {trackPageView} = useMatomo();
  const appUrl = useSelector(selectAppUrl);
  const dentistUuid = useSelector(selectDentistUuid);
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  // Track page view
  React.useEffect(() => {
    if (getCookieConsentValue()) {
      trackPageView({
        documentTitle: 'Summary page',
      });
    }
  }, [trackPageView]);

  const shareData = {
    title: 'Smilefix',
    text: textContent.questions_general_header_contents?.last_page_mobile_share_smilefix_text?.translations[
      selectedLanguage
    ].text,
    url: getShareLinkURL(dentistUuid),
  };

  const onShareButtonPress = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error(err);
      }
    } else {
      await navigator.clipboard.writeText(appUrl);
      alert(
        textContent.questions_general_header_contents?.last_page_link_copied_to_clipboard?.translations[
          selectedLanguage
        ].text,
      );
    }
  };

  const summaryText =
    textContent.questions_general_header_contents?.last_page_summary_field?.translations[selectedLanguage].text;

  return (
    <BackgroundContainer>
      <HeaderLogo />
      <Breadcrumbs pageNumber={3} />
      <ContainerBox>
        <TextBoxContainer>
          <TextBubble
            text={
              textContent.questions_general_header_contents?.last_page_greeting_field.translations[selectedLanguage]
                .text
            }
          />
          <TextBubble text={summaryText} />
        </TextBoxContainer>
        <BigButton
          text={
            textContent.questions_general_header_contents?.last_page_share_smilefix_button.translations[
              selectedLanguage
            ].text
          }
          onClick={() => onShareButtonPress()}
        />
        <Link to={`/?dentist=${dentistUuid}`}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
      </ContainerBox>
    </BackgroundContainer>
  );
}

const TextBoxContainer = styled.div`
  margin-bottom: 30px;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export default Summary;
