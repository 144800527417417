import React from 'react';
import styled from 'styled-components';
import {colors} from '../styles/colors';
//import {ReactComponent as CheckIcon} from '../assets/svg/check_icon.svg';
import {ReactComponent as ChevronLeft} from '../assets/svg/chevron_left.svg';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  selectDentistUuid,
  selectSelectedLanguage,
  selectTextContent,
} from '../features/questionnaire/questionnaireSlice';

interface BreadcrumbsProps {
  pageNumber: number;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {pageNumber} = props;
  const history = useHistory();
  const dentistUuid = useSelector(selectDentistUuid);
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const renderProgressIndicator = () => {
    if (pageNumber !== 4) {
      return (
        <ProgressIndicatorContainer>
          <ProgressIndicator active={pageNumber >= 1}>
            <ProgressIndicatorText>1</ProgressIndicatorText>
          </ProgressIndicator>
          <ProgressIndicatorLine active={pageNumber >= 1} />
          <ProgressIndicator active={pageNumber >= 2}>
            <ProgressIndicatorText>2</ProgressIndicatorText>
          </ProgressIndicator>
          <ProgressIndicatorLine active={pageNumber >= 2} />
          <ProgressIndicator active={pageNumber >= 3}>
            <ProgressIndicatorText>3</ProgressIndicatorText>
            {/*<CheckIcon width={7.5} stroke={colors.progressIndicator.text} />*/}
          </ProgressIndicator>
        </ProgressIndicatorContainer>
      );
    }
    return null;
  };

  const onBackButtonPress = () => {
    switch (pageNumber) {
      case 1:
        history.push(`/?dentist=${dentistUuid}`);
        break;
      case 2:
        history.push(`/pre-questionnaire/?dentist=${dentistUuid}`);
        break;
      case 3:
        history.push(`/questionnaire/?dentist=${dentistUuid}`);
        break;
      case 4:
        history.push(`/summary/?dentist=${dentistUuid}`);
        break;
    }
  };

  return (
    <BreadcrumbsContainer>
      {pageNumber >= 3 ? (
        <Empty />
      ) : (
        <BackButton onClick={onBackButtonPress}>
          <ChevronLeft />
          <BackButtonText>
            {textContent.questions_general_header_contents?.go_back_text.translations[selectedLanguage].text}
          </BackButtonText>
        </BackButton>
      )}
      {renderProgressIndicator()}
      <Empty />
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  max-width: 400px;
  margin-bottom: 20px;
`;
const ProgressIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProgressIndicator = styled.div<{active: boolean}>`
  background: ${(props) =>
    props.active ? colors.progressIndicator.backgroundActive : colors.progressIndicator.backgroundNonActive};
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
`;
const ProgressIndicatorText = styled.span`
  color: ${colors.progressIndicator.text};
  font-family: hoss-round, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  padding-bottom: 2px;
`;
const ProgressIndicatorLine = styled.div<{active: boolean}>`
  width: 16px;
  height: 2px;
  background: ${(props) =>
    props.active ? colors.progressIndicator.backgroundActive : colors.progressIndicator.backgroundNonActive};
`;
const BackButton = styled.button`
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackButtonText = styled.span`
  color: ${colors.linkText};
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.005em;
  margin-left: 8px;
`;
const Empty = styled.div`
  width: 61px;
`;

export default Breadcrumbs;
