import React from 'react';
import {useSelector} from 'react-redux';
import TextBubble from '../../components/TextBubble';
import {selectSelectedLanguage} from './questionnaireSlice';

interface QuestionnaireQuestionProps {
  question: Question;
}

const QuestionnaireHelpText = (props: QuestionnaireQuestionProps) => {
  const {question} = props;
  const selectedLang = useSelector(selectSelectedLanguage);

  /**
   * One brand is used so no need to pull the colors from the backend.
   */

  if (question) {
    return <TextBubble text={question.translations[selectedLang].text} />;
  }
  return null;
};

export default QuestionnaireHelpText;
