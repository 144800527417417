import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/colors';
import {ReactComponent as CameraIcon} from '../assets/svg/camera_icon.svg';

interface iconButtonSquaredProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const IconButtonSquared = (props: iconButtonSquaredProps) => {
  return (
    <IconButtonSquaredContainer onClick={props.onClick} disabled={props.disabled || false}>
      <CameraIcon width={30} />
      <StyledButtonText>{props.text}</StyledButtonText>
    </IconButtonSquaredContainer>
  );
};

const IconButtonSquaredContainer = styled.button`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  background: ${colors.button.background};
  flex: 1 0 auto;
  border: 0;
  :hover {
    background: ${colors.button.hoverBackground};
  }
`;
const StyledButtonText = styled.span`
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: ${colors.button.text};
  margin-top: 8px;
`;

export default IconButtonSquared;
