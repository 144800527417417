import React from 'react';
import styled from 'styled-components';
import {colors} from '../styles/colors';

interface BackgroundContainerProps {
  children: React.ReactNode;
  backgroundURL?: string;
  paddedDown?: boolean;
}

const BackgroundContainer = (props: BackgroundContainerProps) => {
  return <StyledBackgroundContainer paddedDown={props.paddedDown || false}>{props.children}</StyledBackgroundContainer>;
};

const StyledBackgroundContainer = styled.div<{paddedDown: boolean}>`
  background: ${colors.background};
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.paddedDown ? 'flex-end' : 'flex-start')};
  align-items: center;
  min-height: 100%;
`;

export default BackgroundContainer;
