import React from 'react';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import styled from 'styled-components';
import {getCookieConsentValue} from 'react-cookie-consent';
import ContainerBox from '../components/ContainerBox';
import {BoldText, SmallTitleLeft} from '../styles/typography';
import BackgroundContainer from '../components/BackgroundContainer';
import QuestionnaireWebcam from '../features/questionnaire/QuestionnaireWebcam';
import Questions from '../features/questionnaire/Questions';
import Breadcrumbs from '../components/Breadcrumbs';
import HeaderLogo from '../components/HeaderLogo';
import {useSelector} from 'react-redux';
import {selectSelectedLanguage, selectTextContent} from '../features/questionnaire/questionnaireSlice';

function PreQuestionnaire() {
  const {trackPageView} = useMatomo();
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  // Track page view
  React.useEffect(() => {
    if (getCookieConsentValue()) {
      trackPageView({
        documentTitle: 'Pre questionnaire page',
      });
    }
  }, [trackPageView]);

  return (
    <BackgroundContainer>
      <HeaderLogo />
      <Breadcrumbs pageNumber={1} />
      <ContainerBox>
        <TitleContainer>
          <SmallTitleLeft>
            {
              textContent.questions_general_header_contents?.pre_questionnaire_page_header.translations[
                selectedLanguage
              ].text
            }
          </SmallTitleLeft>
        </TitleContainer>
        <BoldText>
          {
            textContent.questions_general_header_contents?.pre_questionnaire_page_intro.translations[selectedLanguage]
              .text
          }
        </BoldText>
        <Questions pageNumber={1} />
        <QuestionnaireWebcam />
      </ContainerBox>
    </BackgroundContainer>
  );
}

const TitleContainer = styled.div`
  margin-bottom: 16px;
`;

export default PreQuestionnaire;
