import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from '../../redux/store';
import {Language} from '../../types/language';
const API_BASE_URL = process.env.REACT_APP_API_BASE;

interface AppSliceState {
  isLoading: boolean;
  error: string | null;
  questions: Question[];
  url: string;
  logoURL: string | null;
  dentistNumber: number | null;
  name: string;
  motto: string;
  answers: Answer[];
  mouthImage: string | null;
  contactFreeText: string;
  contactFirstName: string;
  contactLastName: string;
  contactGender: 'm' | 'f' | 'nb' | 'x' | null;
  contactYearOfBirth: string;
  contactEmail: string;
  contactPhoneNumber: string;
  contactZipCode: string;
  contactCountry: string;
  contactOpenText: string;
  contactTermsAgree: boolean;
  forwardToSummary: boolean;
  dentistUuid: string | null;
  appUrl: string;
  defaultLanguage: Language;
  defaultCustomerLanguage: string;
  languages: Language[];
  selectedLanguage: string;
  imageFromDevice: boolean;
  textContent: {
    questions_general_header_contents?: any;
    questions_personal_details_contents?: any;
  };
}

const slice = createSlice({
  name: 'questionnaire',
  initialState: {
    error: null,
    isLoading: false,
    questions: [],
    logoURL: null,
    url: '',
    name: '',
    motto: '',
    answers: [],
    mouthImage: null,
    contactFreeText: '',
    contactFirstName: '',
    contactLastName: '',
    contactGender: null,
    contactYearOfBirth: '',
    contactEmail: '',
    contactPhoneNumber: '',
    contactZipCode: '',
    contactCountry: '',
    contactOpenText: '',
    contactTermsAgree: false,
    forwardToSummary: false,
    dentistNumber: null,
    dentistUuid: null,
    appUrl: '',
    defaultLanguage: {language: 'en', language_name: 'English'},
    defaultCustomerLanguage: 'en',
    languages: [],
    selectedLanguage: 'en',
    imageFromDevice: false,
    textContent: {},
  } as AppSliceState,
  reducers: {
    setImageFromDevice: (state, action: PayloadAction<boolean>) => {
      state.imageFromDevice = action.payload;
    },
    setAppUrl: (state, action: PayloadAction<string>) => {
      state.appUrl = action.payload;
    },
    setDentistUuid: (state, action: PayloadAction<string>) => {
      state.dentistUuid = action.payload;
    },
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    getQuestionsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getQuestionsSuccess: (state, action: PayloadAction<any>) => {
      const payload = action.payload[0];
      const dentistNumber = payload.id;
      if (dentistNumber) {
        state.dentistNumber = dentistNumber;
      }
      state.error = null;
      state.isLoading = false;
      state.questions = payload.questions;
      state.url = payload.url;
      state.name = payload.name;
      state.motto = payload.motto;
      state.logoURL = payload.logo;

      // Set languages
      state.defaultLanguage = {
        language: payload.default_language,
        language_name: payload.default_language_name,
      };
      state.languages = payload.extra_languages;
      state.selectedLanguage = payload.customer_default_language;

      state.textContent = {
        questions_general_header_contents: payload.questions_general_header_contents,
        questions_personal_details_contents: payload.questions_personal_details_contents,
      };

      // Populate answers:
      if (payload.questions) {
        const questionsToBeAnswered = payload.questions.filter(
          (q: Question) => q.resourcetype === 'QuestionOptions' || q.resourcetype === 'QuestionSlider',
        );
        const answers: Answer[] = questionsToBeAnswered.map((q: Question) => {
          if (q.resourcetype === 'QuestionOptions') {
            return {question: q.pk, value: null};
          } else {
            return {question: q.pk, value: 0};
          }
        });
        if (answers) {
          state.answers = answers;
        }
      }
    },
    getQuestionsFailure: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearQuestions: (state) => {
      state.questions = [];
      state.answers = [];
      state.url = '';
      state.name = '';
      state.motto = '';
    },
    setAnswer: (state, action: PayloadAction<Answer>) => {
      if (state.answers.length === 0) {
        state.answers.push(action.payload);
      } else {
        const oldAnswerIndex = state.answers.findIndex((answer) => answer.question === action.payload.question);
        if (oldAnswerIndex !== -1) {
          state.answers[oldAnswerIndex].value = action.payload.value;
        } else {
          state.answers.push(action.payload);
        }
      }
    },
    setMouthImage: (state, action: PayloadAction<string | null>) => {
      state.mouthImage = action.payload;
    },
    setContactFreeText: (state, action: PayloadAction<string>) => {
      state.contactFreeText = action.payload;
    },
    setContactFirstName: (state, action: PayloadAction<string>) => {
      state.contactFirstName = action.payload;
    },
    setContactLastName: (state, action: PayloadAction<string>) => {
      state.contactLastName = action.payload;
    },
    setContactGender: (state, action: PayloadAction<'m' | 'f' | 'nb' | 'x' | null>) => {
      state.contactGender = action.payload;
    },
    setContactYearOfBirth: (state, action: PayloadAction<string>) => {
      state.contactYearOfBirth = action.payload;
    },
    setContactEmail: (state, action: PayloadAction<string>) => {
      state.contactEmail = action.payload;
    },
    setContactPhoneNumber: (state, action: PayloadAction<string>) => {
      state.contactPhoneNumber = action.payload;
    },
    setContactZipCode: (state, action: PayloadAction<string>) => {
      state.contactZipCode = action.payload;
    },
    setContactCountry: (state, action: PayloadAction<string>) => {
      state.contactCountry = action.payload;
    },
    setContactOpenText: (state, action: PayloadAction<string>) => {
      state.contactOpenText = action.payload;
    },
    setContactTermsAgree: (state, action: PayloadAction<boolean>) => {
      state.contactTermsAgree = action.payload;
    },
    postAnswersRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    postAnswerSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
      state.forwardToSummary = true;
    },
    postAnswerFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setForwardToSummary: (state, action: PayloadAction<boolean>) => {
      state.forwardToSummary = action.payload;
    },
  },
});

export const getQuestions = (dentistNumber?: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.questionnaire.isLoading) {
    return;
  }

  dispatch(getQuestionsRequest());

  // If dentist uuid4 number is given, use that to fetch dentist-specific data.
  // Otherwise get general data of the superuser dentist.
  const reqUrl = dentistNumber ? `?uuid=${dentistNumber}` : '';

  return fetch(`${API_BASE_URL}/api/public/chatbot/${reqUrl}`)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        dispatch(getQuestionsSuccess(data));
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch(getQuestionsFailure('Error fetching fields'));
    });
};

export const setNewAnswer = (a: Answer) => (dispatch: AppDispatch) => {
  dispatch(setAnswer(a));
};

export const setNewMouthImage = (image: string | null) => (dispatch: AppDispatch) => {
  dispatch(setMouthImage(image));
};

export const sendAnswers = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(postAnswersRequest());
  const state = getState();

  // Backend wants info about what language customer used to fill out the form.
  // Use the default language if there is no extra languages available that are selected.
  let selectedLanguageName = state.questionnaire.defaultLanguage.language_name;
  if (state.questionnaire.selectedLanguage !== state.questionnaire.defaultLanguage.language) {
    selectedLanguageName = state.questionnaire.languages.filter(
      (language: Language) => language.language === state.questionnaire.selectedLanguage,
    )[0].language_name;
  }

  if (state.questionnaire.questions && state.questionnaire.answers) {
    const data: AnswerPostData = {
      dentist: state.questionnaire.dentistNumber || 1,
      free_text: state.questionnaire.contactFreeText,
      first_name: state.questionnaire.contactFirstName,
      last_name: state.questionnaire.contactLastName,
      birth_year: parseInt(state.questionnaire.contactYearOfBirth),
      gender: state.questionnaire.contactGender,
      email: state.questionnaire.contactEmail,
      phone: state.questionnaire.contactPhoneNumber,
      country: state.questionnaire.contactCountry,
      zip_code: state.questionnaire.contactZipCode,
      used_language: selectedLanguageName,
      answers: state.questionnaire.answers,
      image: state.questionnaire.mouthImage,
    };

    return fetch(`${API_BASE_URL}/api/public/answer/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postAnswerFailure('Failed to post answers'));
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        dispatch(postAnswerSuccess());
      })
      .catch((error) => {
        dispatch(postAnswerFailure('Failed to post answers'));
        console.error('Error:', error);
      });
  }

  dispatch(postAnswerFailure('Error: No questions or answers'));
};

export const selectQuestionsLoading = (state: RootState) => state.questionnaire.isLoading;
export const selectQuestions = (state: RootState) => state.questionnaire.questions;
export const selectAnswers = (state: RootState) => state.questionnaire.answers;
export const selectMouthImage = (state: RootState) => state.questionnaire.mouthImage;
export const selectContactFreeText = (state: RootState) => state.questionnaire.contactFreeText;
export const selectContactFirstName = (state: RootState) => state.questionnaire.contactFirstName;
export const selectContactLastName = (state: RootState) => state.questionnaire.contactLastName;
export const selectContactGender = (state: RootState) => state.questionnaire.contactGender;
export const selectContactCountry = (state: RootState) => state.questionnaire.contactCountry;
export const selectContactEmail = (state: RootState) => state.questionnaire.contactEmail;
export const selectContactPhoneNumber = (state: RootState) => state.questionnaire.contactPhoneNumber;
export const selectContactTermsAgree = (state: RootState) => state.questionnaire.contactTermsAgree;
export const selectContactYearOfBirth = (state: RootState) => state.questionnaire.contactYearOfBirth;
export const selectContactZipCode = (state: RootState) => state.questionnaire.contactZipCode;
export const selectForwardToSummary = (state: RootState) => state.questionnaire.forwardToSummary;
export const selectDentistNumber = (state: RootState) => state.questionnaire.dentistNumber;
export const selectDentistLogoURL = (state: RootState) => state.questionnaire.logoURL;
export const selectAppUrl = (state: RootState) => state.questionnaire.appUrl;
export const selectDefaultLanguage = (state: RootState) => state.questionnaire.defaultLanguage;
export const selectLanguages = (state: RootState) => state.questionnaire.languages;
export const selectDentistUuid = (state: RootState) => state.questionnaire.dentistUuid;
export const selectSelectedLanguage = (state: RootState) => state.questionnaire.selectedLanguage;
export const selectImageFromDevice = (state: RootState) => state.questionnaire.imageFromDevice;
export const selectTextContent = (state: RootState) => state.questionnaire.textContent;

export const {
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsFailure,
  clearQuestions,
  setAnswer,
  setMouthImage,
  setContactFreeText,
  setContactFirstName,
  setContactLastName,
  setContactGender,
  setContactCountry,
  setContactEmail,
  setContactOpenText,
  setContactPhoneNumber,
  setContactTermsAgree,
  setContactYearOfBirth,
  setContactZipCode,
  postAnswersRequest,
  postAnswerFailure,
  postAnswerSuccess,
  setForwardToSummary,
  setAppUrl,
  setDentistUuid,
  setSelectedLanguage,
  setImageFromDevice,
} = slice.actions;

export default slice.reducer;
