import React from 'react';
import styled from 'styled-components';
import {colors} from '../styles/colors';
import {ButtonText} from '../styles/typography';
import {ReactComponent as CameraIcon} from '../assets/svg/camera_icon.svg';

interface iconButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  id?: string;
}

const IconButton = (props: iconButtonProps) => {
  return (
    <StyledIconButton id={props.id} onClick={props.onClick} disabled={props.disabled || false}>
      <CameraIcon width={30} />
      <IconButtonText>{props.text}</IconButtonText>
    </StyledIconButton>
  );
};

const StyledIconButton = styled.button<{disabled: boolean}>`
  padding-top: 20px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  background: ${(props) => (props.disabled ? colors.button.disabledBackground : colors.button.background)};
  width: 100%;
  border: 0;
  :hover {
    background: ${(props) => (props.disabled ? colors.button.disabledBackground : colors.button.hoverBackground)};
  }
`;
const IconButtonText = styled(ButtonText)`
  margin-left: 5px;
`;

export default IconButton;
