import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import {selectAnswers, selectQuestions, setNewAnswer} from './questionnaireSlice';
import QuestionnaireHelpText from './QuestionnaireHelpText';
import QuestionnaireYesNo from './QuestionnaireYesNo';
import QuestionnaireHelpImage from './QuestionHelpImage';
import QuestionnaireSlider from './QuestionnaireSlider';

interface QuestionsProps {
  pageNumber: number;
}

function Questions(props: QuestionsProps) {
  const {trackEvent} = useMatomo();
  const dispatch = useDispatch();
  const questions = useSelector(selectQuestions).filter((item: Question) => item.page === props.pageNumber);
  const answers = useSelector(selectAnswers);
  const answersWithNullValue = answers.filter((a: Answer) => a.value === null);

  const onYesNoClick = (id: 0 | 1 | 2, question: Question) => {
    if (question && question.pk) {
      if (getCookieConsentValue()) {
        trackEvent({category: `Yes No Question: ${question.pk}`, action: 'Value selected', value: id});
      }
      const answer: Answer = {question: question.pk, value: id};
      dispatch(setNewAnswer(answer));
    }
  };

  const onSliderValueChange = (value: number, question: Question) => {
    if (question && question.pk) {
      if (getCookieConsentValue()) {
        trackEvent({category: `Slider Question: ${question.pk}`, action: 'Value selected', value: value});
      }
      const answer: Answer = {question: question.pk, value: value};
      dispatch(setNewAnswer(answer));
    }
  };

  const renderQuestions = () => {
    if (questions) {
      return questions.map((item) => {
        const answerIndex = answers.findIndex((i) => i.question === item.pk);
        let selectedValue = null;
        if (answerIndex !== -1) {
          selectedValue = answers[answerIndex].value;
        }
        /**
         * Render questions only after answers have been given:
         */
        if (item.ordering && answersWithNullValue.length > 0) {
          if (item.ordering > answersWithNullValue[0].question) {
            return null;
          }
        }
        switch (item.resourcetype) {
          default:
            return null;
          case 'QuestionHelpText':
            return <QuestionnaireHelpText question={item} key={item.ordering} />;
          case 'QuestionOptions':
            return (
              <QuestionnaireYesNo
                question={item}
                key={item.ordering}
                onClickYes={() => onYesNoClick(0, item)}
                onClickNo={() => onYesNoClick(1, item)}
                onClickSkip={() => onYesNoClick(2, item)}
                selectedValue={selectedValue}
              />
            );
          case 'QuestionHelpImage':
            if (item.image) {
              return <QuestionnaireHelpImage src={item.image} alt={item.resourcetype} key={item.ordering} />;
            }
            return null;
          case 'QuestionSlider':
            return (
              <QuestionnaireSlider
                question={item}
                selectedValue={selectedValue}
                onValueChange={(value: number) => onSliderValueChange(value, item)}
                key={item.ordering}
              />
            );
        }
      });
    }
    return null;
  };

  if (questions) {
    return <QuestionsContainer>{renderQuestions()}</QuestionsContainer>;
  }

  return null;
}

const QuestionsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

export default Questions;
