import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import BackgroundContainer from '../components/BackgroundContainer';
import ContainerBox from '../components/ContainerBox';
import WebcamResult from '../features/questionnaire/WebcamResult';
import Questions from '../features/questionnaire/Questions';
import Breadcrumbs from '../components/Breadcrumbs';
import ContactForm from '../features/questionnaire/ContactForm';
import {
  selectDentistUuid,
  selectForwardToSummary,
  setForwardToSummary,
} from '../features/questionnaire/questionnaireSlice';
import HeaderLogo from '../components/HeaderLogo';

function Questionnaire() {
  const {trackPageView} = useMatomo();
  const dentistUuid = useSelector(selectDentistUuid);

  // Track page view
  React.useEffect(() => {
    if (getCookieConsentValue()) {
      trackPageView({
        documentTitle: 'Questionnaire page',
      });
    }
  }, [trackPageView]);

  const history = useHistory();
  const dispatch = useDispatch();
  const forwardToSummary = useSelector(selectForwardToSummary);

  if (forwardToSummary) {
    dispatch(setForwardToSummary(false));
    history.push(`/summary/?dentist=${dentistUuid}`);
  }

  return (
    <BackgroundContainer>
      <HeaderLogo />
      <Breadcrumbs pageNumber={2} />
      <ContainerBox>
        <WebcamResult />
        <Questions pageNumber={2} />
        <ContactForm />
      </ContainerBox>
    </BackgroundContainer>
  );
}

export default Questionnaire;
