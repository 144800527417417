// Main Colors:
const freshBlue = '#1D32B0';
const mintyMint = '#8CD0D8';
const mintyMintDark = '#7DB9BC';
const pureSmile = '#F0FAF9';
const white = '#FFFFFF';
const black = '#000000';
const scaryCavities = '#C8C8C8';
const candyCaries = '#383838';
const negative = '#FF637F';

export const colors = {
  text: {
    blue: freshBlue,
    white: white,
  },
  background: mintyMint,
  titleText: candyCaries,
  linkText: freshBlue,
  button: {
    background: freshBlue,
    hoverBackground: negative,
    disabledBackground: scaryCavities,
    text: white,
  },
  contentContainer: {
    background: pureSmile,
  },
  yesNoButton: {
    yesButtonBackground: freshBlue,
    noButtonBackground: negative,
    text: white,
    skipText: freshBlue,
  },
  white: white,
  black: black,
  progressIndicator: {
    backgroundActive: negative,
    backgroundNonActive: freshBlue,
    text: white,
  },
  textBubble: {
    background: mintyMintDark,
    text: white,
  },
  modal: {
    containerBackground: scaryCavities,
    contentContainerBackground: mintyMint,
    text: white,
  },
};
