import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import ContainerBox from '../components/ContainerBox';
import BackgroundContainer from '../components/BackgroundContainer';

function Share() {
  return (
    <BackgroundContainer>
      <Breadcrumbs pageNumber={4} />
      <ContainerBox>
        <p>share</p>
      </ContainerBox>
    </BackgroundContainer>
  );
}

export default Share;
