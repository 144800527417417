import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/colors';
import {BodyTextWhite, Title} from '../styles/typography';
import {ReactComponent as CloseIcon} from '../assets/svg/cross_icon.svg';
import ScrollToTop from './ScrollToTop';
import BigButton from './BigButton';

interface TextBubbleProps {
  text: string;
  show: boolean;
  onClose: () => void;
}

const Modal = (props: TextBubbleProps) => {
  if (props.show) {
    return (
      <>
        <ScrollToTop />
        <ModalContainer>
          <ModalContentContainer>
            <CloseButtonContainer>
              <CloseButton onClick={props.onClose}>
                <CloseIcon />
              </CloseButton>
            </CloseButtonContainer>
            <TextContainer>
              <StyledTitle>Terms of service</StyledTitle>
              <BodyTextWhite>{props.text}</BodyTextWhite>
            </TextContainer>
            <BigButton text="Close" onClick={props.onClose} />
          </ModalContentContainer>
        </ModalContainer>
      </>
    );
  }
  return null;
};

const ModalContainer = styled.div`
  position: absolute;
  background: ${colors.modal.containerBackground};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`;
const ModalContentContainer = styled.div`
  border-radius: 18px;
  padding: 20px;
  background: ${colors.modal.contentContainerBackground};
`;
const CloseButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
  margin-right: 15px;
  justify-content: flex-end;
`;
const TextContainer = styled.div`
  padding: 20px 10px;
`;
const StyledTitle = styled(Title)`
  color: ${colors.modal.text};
  text-align: left;
  margin-bottom: 20px;
`;
const CloseButton = styled.button`
  border: 0;
  background: transparent;
`;

export default Modal;
