import React from 'react';
import styled from 'styled-components';

interface QuestionnaireHelpImageProps {
  src: string;
  alt: string;
  squared?: boolean;
}

const QuestionnaireHelpImage = (props: QuestionnaireHelpImageProps) => {
  return (
    <StyledImageContainer squared={props.squared || false}>
      <StyledImage src={props.src} alt={props.alt} />
    </StyledImageContainer>
  );
};

const StyledImageContainer = styled.div<{squared: boolean}>`
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const StyledImage = styled.img`
  border-radius: 16px;
  display: block;
  max-width: 100%;
`;

export default QuestionnaireHelpImage;
