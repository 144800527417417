import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/colors';

interface ContainerBoxProps {
  children: React.ReactNode;
}

const ContainerBox = (props: ContainerBoxProps) => {
  return <StyledContainerBox>{props.children}</StyledContainerBox>;
};

const StyledContainerBox = styled.div`
  background: ${colors.contentContainer.background};
  border-radius: 32px;
  padding: 38px 14px 14px 14px;
  display: flex;
  flex-direction: column;
  max-width: 372px;
  margin-bottom: 20px;
`;

export default ContainerBox;
