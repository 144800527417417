import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import LandingPage from './routes/LandingPage';
import PreQuestionnaire from './routes/PreQuestionnaire';
import Questionnaire from './routes/Questionnaire';
import Summary from './routes/Summary';
import Share from './routes/Share';
import ScrollToTop from './components/ScrollToTop';
import {useDispatch} from 'react-redux';
import {getQuestions, setAppUrl, setDentistUuid} from './features/questionnaire/questionnaireSlice';
import {colors} from './styles/colors';

function App() {
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  React.useEffect(() => {
    dispatch(setAppUrl(window.location.toString()));

    if (params.dentist) {
      const queryParamString = params.dentist;
      if (queryParamString) {
        dispatch(getQuestions(queryParamString));
        dispatch(setDentistUuid(queryParamString));
      }
    } else {
      dispatch(getQuestions());
    }
  }, [params, dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Ok!"
        declineButtonText="No thanks!"
        buttonStyle={{
          fontFamily: 'hoss-round, sans-serif',
          fontSize: '16px',
          background: `${colors.yesNoButton.yesButtonBackground}`,
          color: `${colors.yesNoButton.text}`,
        }}
        declineButtonStyle={{
          fontFamily: 'hoss-round, sans-serif',
          fontSize: '16px',
          background: `${colors.yesNoButton.noButtonBackground}`,
        }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Switch>
        <Route path="/pre-questionnaire">
          <PreQuestionnaire />
        </Route>
        <Route path="/questionnaire">
          <Questionnaire />
        </Route>
        <Route path="/summary">
          <Summary />
        </Route>
        <Route path="/share">
          <Share />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
