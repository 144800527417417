import React from 'react';
import styled from 'styled-components';
import {colors} from '../../styles/colors';
import {ButtonText} from '../../styles/typography';
import {ReactComponent as Checkmark} from '../../assets/svg/check_icon.svg';
import {selectSelectedLanguage} from './questionnaireSlice';
import {useSelector} from 'react-redux';

interface QuestionnaireYesNoProps {
  question: Question;
  selectedValue: number | null;
  onClickYes: () => void;
  onClickNo: () => void;
  onClickSkip: () => void;
}

const QuestionnaireYesNo = (props: QuestionnaireYesNoProps) => {
  const {question} = props;
  const selectedLang = useSelector(selectSelectedLanguage);

  return (
    <QuestionnaireYesNoContainer id={`yes-no-${question.pk}`}>
      <YesButton onClick={props.onClickYes}>
        {props.selectedValue === 0 ? (
          <CheckmarkContainer>
            <Checkmark stroke={colors.background} width={30} height={22} />
          </CheckmarkContainer>
        ) : null}
        <ButtonText>{question.translations[selectedLang].option1}</ButtonText>
      </YesButton>
      <NoButton onClick={props.onClickNo}>
        {props.selectedValue === 1 ? (
          <CheckmarkContainer>
            <Checkmark stroke={colors.background} width={30} height={22} />
          </CheckmarkContainer>
        ) : null}
        <ButtonText>{question.translations[selectedLang].option2}</ButtonText>
      </NoButton>
      <SkipButton onClick={props.onClickSkip}>
        {props.selectedValue === 2 ? (
          <CheckmarkContainer>
            <Checkmark stroke={colors.background} width={30} height={22} />
          </CheckmarkContainer>
        ) : null}
        <SkipButtonText>{question.translations[selectedLang].option3}</SkipButtonText>
      </SkipButton>
    </QuestionnaireYesNoContainer>
  );
};

const QuestionnaireYesNoContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BaseButton = styled.button`
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #c8c8c8;
  margin-right: 5px;
  position: relative;
`;
const CheckmarkContainer = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: -5px;
  right: 0;
  z-index: 9;
`;

const YesButton = styled(BaseButton)`
  background: ${colors.yesNoButton.yesButtonBackground};
`;

const NoButton = styled(BaseButton)`
  background: ${colors.yesNoButton.noButtonBackground};
`;

const SkipButton = styled(BaseButton)`
  background: transparent;
  border: 0;
  box-shadow: 0px 0px 0px transparent;
`;

const SkipButtonText = styled(ButtonText)`
  color: ${colors.yesNoButton.skipText};
`;

export default QuestionnaireYesNo;
