import React, {useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import BigButton from '../../components/BigButton';
import {colors} from '../../styles/colors';
import {
  setContactFirstName,
  setContactLastName,
  setContactGender,
  setContactCountry,
  setContactEmail,
  setContactPhoneNumber,
  setContactTermsAgree,
  setContactYearOfBirth,
  setContactZipCode,
  selectContactFirstName,
  selectContactCountry,
  selectContactEmail,
  selectContactGender,
  selectContactLastName,
  selectContactPhoneNumber,
  selectContactTermsAgree,
  selectContactYearOfBirth,
  selectContactZipCode,
  selectMouthImage,
  sendAnswers,
  selectDentistNumber,
  selectDentistUuid,
  selectTextContent,
  selectSelectedLanguage,
  selectContactFreeText,
  setContactFreeText,
} from './questionnaireSlice';
import Modal from '../../components/Modal';

function ContactForm() {
  const dispatch = useDispatch();
  const {trackEvent} = useMatomo();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const contactFreeText = useSelector(selectContactFreeText);
  const contactFirstName = useSelector(selectContactFirstName);
  const contactLastName = useSelector(selectContactLastName);
  const contactGender = useSelector(selectContactGender);
  const contactYearOfBirth = useSelector(selectContactYearOfBirth);
  const contactEmail = useSelector(selectContactEmail);
  const contactPhoneNumber = useSelector(selectContactPhoneNumber);
  const contactZipCode = useSelector(selectContactZipCode);
  const contactCountry = useSelector(selectContactCountry);
  const contactTermsAgree = useSelector(selectContactTermsAgree);
  const mouthImage = useSelector(selectMouthImage);
  const dentistUuid = useSelector(selectDentistUuid);
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const dentistNumber = useSelector(selectDentistNumber);

  function validateEmail(email: string): boolean {
    return /^\S.*@\S+\.\S+$/.test(email);
  }

  function validateYearOfBirth(year: string): boolean {
    const currYear = new Date().getFullYear();
    return /^(19|20)\d{2}$/.test(year) && new Date(year).getFullYear() <= currYear;
  }

  const formValidation = () => {
    let errors = [];
    setErrorMessages([]);

    const isFirstNameValid = contactFirstName !== '';
    const isLastNameValid = contactLastName !== '';
    const isValidMouthImage = mouthImage !== null;
    const isValidPhoneNumber = contactPhoneNumber !== '';
    const isValidZipCode = contactZipCode !== '';
    const isValidCountry = contactCountry !== '';

    if (!isFirstNameValid) {
      errors.push('First Name is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: First name'});
      }
    }
    if (!isLastNameValid) {
      errors.push('Last Name is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Last name'});
      }
    }
    if (!validateYearOfBirth(contactYearOfBirth)) {
      errors.push('Year of birth is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Birth year'});
      }
    }
    if (!validateEmail(contactEmail)) {
      errors.push('Email is not valid format, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Email'});
      }
    }
    if (!isValidMouthImage) {
      errors.push('Image not available, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Image missing'});
      }
    }
    if (!isValidPhoneNumber) {
      errors.push('Phone number is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Phone number'});
      }
    }
    if (!isValidZipCode) {
      errors.push('Zip code is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Zip code'});
      }
    }
    if (!isValidCountry) {
      errors.push('Country is not valid, please try again.');
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit fail: Country'});
      }
    }
    if (errors.length > 0) {
      setShowErrors(true);
      setErrorMessages(errors);
    } else {
      setShowErrors(false);
      dispatch(sendAnswers());
      if (getCookieConsentValue()) {
        trackEvent({category: 'Form submit', action: 'Form submit successful'});
      }
    }
  };

  const disableDoneButton = () => {
    if (!contactTermsAgree) {
      return true;
    }
    if (contactFirstName === '') {
      return true;
    }
    if (contactLastName === '') {
      return true;
    }
    if (contactYearOfBirth === '') {
      return true;
    }
    if (contactEmail === '') {
      return true;
    }
    if (mouthImage === null) {
      return true;
    }
    if (contactGender === null) {
      return true;
    }
    if (contactPhoneNumber === '') {
      return true;
    }
    if (contactZipCode === '') {
      return true;
    }
    if (contactCountry === '') {
      return true;
    }
    return false;
  };

  return (
    <ContactFormContainer>
      <form>
        <InputContainer>
          <StyledLabel htmlFor="firstName">
            {
              textContent.questions_general_header_contents?.form_page_free_text_field.translations[selectedLanguage]
                .text
            }
          </StyledLabel>
          <StyledTextInputField
            maxLength={200}
            type="text"
            value={contactFreeText}
            onChange={(e) => dispatch(setContactFreeText(e.target.value))}
          />
        </InputContainer>

        <InputContainer>
          <StyledLabel htmlFor="firstName">
            {
              textContent.questions_personal_details_contents?.last_page_first_name_field.translations[selectedLanguage]
                .text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactFirstName}
            onChange={(e) => dispatch(setContactFirstName(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="lastName">
            {
              textContent.questions_personal_details_contents?.last_page_last_name_field.translations[selectedLanguage]
                .text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactLastName}
            onChange={(e) => dispatch(setContactLastName(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="gender">
            {
              textContent.questions_personal_details_contents?.last_page_gender_field.translations[selectedLanguage]
                .text
            }{' '}
            *
          </StyledLabel>
          <StyledRadioContainer>
            <StyledRadio
              type="radio"
              name="gender"
              value="f"
              onChange={() => dispatch(setContactGender('f'))}
              checked={contactGender === 'f'}
            />
            <StyledLabel htmlFor="gender">
              {
                textContent.questions_personal_details_contents?.last_page_gender_field_female.translations[
                  selectedLanguage
                ].text
              }
            </StyledLabel>
          </StyledRadioContainer>
          <StyledRadioContainer>
            <StyledRadio
              type="radio"
              name="gender"
              value="m"
              onChange={() => dispatch(setContactGender('m'))}
              checked={contactGender === 'm'}
            />
            <StyledLabel htmlFor="gender">
              {
                textContent.questions_personal_details_contents?.last_page_gender_field_male.translations[
                  selectedLanguage
                ].text
              }
            </StyledLabel>
          </StyledRadioContainer>
          <StyledRadioContainer>
            <StyledRadio
              type="radio"
              name="gender"
              value="nb"
              onChange={() => dispatch(setContactGender('nb'))}
              checked={contactGender === 'nb'}
            />
            <StyledLabel htmlFor="gender">
              {
                textContent.questions_personal_details_contents?.last_page_gender_field_nonbinary.translations[
                  selectedLanguage
                ].text
              }
            </StyledLabel>
          </StyledRadioContainer>
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="yearOfBirth">
            {
              textContent.questions_personal_details_contents?.last_page_year_of_birth_field.translations[
                selectedLanguage
              ].text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactYearOfBirth}
            onChange={(e) => dispatch(setContactYearOfBirth(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="email">
            {textContent.questions_personal_details_contents?.last_page_email_field.translations[selectedLanguage].text}{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactEmail}
            onChange={(e) => dispatch(setContactEmail(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="phoneNumber">
            {
              textContent.questions_personal_details_contents?.last_page_phone_number_field.translations[
                selectedLanguage
              ].text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactPhoneNumber}
            onChange={(e) => dispatch(setContactPhoneNumber(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="zipCode">
            {
              textContent.questions_personal_details_contents?.last_page_zip_code_field.translations[selectedLanguage]
                .text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactZipCode}
            onChange={(e) => dispatch(setContactZipCode(e.target.value))}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel htmlFor="Country">
            {
              textContent.questions_personal_details_contents?.last_page_country_field.translations[selectedLanguage]
                .text
            }{' '}
            *
          </StyledLabel>
          <StyledTextInputField
            type="text"
            value={contactCountry}
            onChange={(e) => dispatch(setContactCountry(e.target.value))}
          />
        </InputContainer>
        <CheckBoxContainer>
          <StyledCheckBox
            type="checkbox"
            checked={contactTermsAgree}
            onChange={(e) => dispatch(setContactTermsAgree(e.target.checked))}
          />
          <StyledLabel htmlFor="termsAgree" onClick={() => setShowTerms(true)}>
            {
              textContent.questions_general_header_contents?.form_page_terms_of_service_tab.translations[
                selectedLanguage
              ].text
            }
          </StyledLabel>
        </CheckBoxContainer>
        {dentistUuid && dentistNumber && dentistNumber > 1 && (
          <BigButton
            onClick={formValidation}
            text={
              textContent.questions_general_header_contents?.form_page_done_button.translations[selectedLanguage].text
            }
            disabled={disableDoneButton()}
          />
        )}
        {(!dentistUuid || (dentistNumber && dentistNumber === 1)) && (
          <p>
            Thanks for trying the demo! To fill out the official questionnaire, you need a link from your Smilefix
            dentist.
          </p>
        )}
        {showErrors
          ? errorMessages.map((item, index) => {
              return <ul key={index}>{item}</ul>;
            })
          : null}
      </form>
      <Modal
        onClose={() => setShowTerms(false)}
        text={
          textContent.questions_general_header_contents?.form_page_terms_of_service_text.translations[selectedLanguage]
            .text
        }
        show={showTerms}
      />
    </ContactFormContainer>
  );
}

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 14px;
  margin-right: 14px;
`;
const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const StyledLabel = styled.label`
  width: 100%;
  font-family: hoss-round, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: ${colors.text.blue};
  margin-bottom: 5px;
`;
const StyledTextInputField = styled.input`
  padding: 10px;
  width: 100%;
  border-radius: 12px;
  border: 0;
`;
const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
const StyledRadio = styled.input`
  width: 25px;
  height: 25px;
  margin-right: 12px;
`;
const StyledCheckBox = styled.input`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export default ContactForm;
