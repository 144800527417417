import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {useDispatch, useSelector} from 'react-redux';
import {getCookieConsentValue} from 'react-cookie-consent';

import BackgroundContainer from '../components/BackgroundContainer';
import ContainerBox from '../components/ContainerBox';
import {Title, BoldText} from '../styles/typography';
import BigButton from '../components/BigButton';
import {
  selectDefaultLanguage,
  selectDentistUuid,
  selectLanguages,
  selectSelectedLanguage,
  selectTextContent,
  setSelectedLanguage,
} from '../features/questionnaire/questionnaireSlice';
import {ReactComponent as Logo} from '../assets/svg/smilefix_powered_by_logo.svg';
import DentistLogo from '../features/questionnaire/DentistLogo';
import {Language} from '../types/language';

function LandingPage() {
  const history = useHistory();
  const {trackPageView} = useMatomo();
  const dentistUuid = useSelector(selectDentistUuid);
  const defaultLanguage = useSelector(selectDefaultLanguage);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const languages = useSelector(selectLanguages);
  const textContent = useSelector(selectTextContent);
  const dispatch = useDispatch();

  // Track page view
  React.useEffect(() => {
    if (getCookieConsentValue()) {
      trackPageView({
        documentTitle: 'Landing page',
      });
    }
  }, [trackPageView]);

  return (
    <BackgroundContainer paddedDown={true}>
      <DentistLogo />
      <div style={{marginBottom: '24px'}}>
        <select
          value={selectedLanguage}
          onChange={(e) => {
            dispatch(setSelectedLanguage(e.target.value));
          }}>
          <option value={defaultLanguage.language}>{defaultLanguage.language_name}</option>
          {languages.map((language: Language) => (
            <option key={language.language} value={language.language}>
              {language.language_name}
            </option>
          ))}
        </select>
      </div>
      <ContainerBox>
        <TextBox>
          <TitleContainer>
            {textContent.questions_general_header_contents && (
              <Title>
                {textContent.questions_general_header_contents?.landing_page_header.translations[selectedLanguage].text}
              </Title>
            )}
            {!textContent.questions_general_header_contents && <Title>Let's SMILEFIX your life</Title>}
          </TitleContainer>
          {textContent.questions_general_header_contents && (
            <BoldText>
              {textContent.questions_general_header_contents?.landing_page_intro.translations[selectedLanguage].text}
            </BoldText>
          )}
          {!textContent.questions_general_header_contents && (
            <BoldText>Answer few questions to get a free treatment plan and quotation for your new smile.</BoldText>
          )}
        </TextBox>

        <BigButton
          text={
            textContent.questions_general_header_contents
              ? textContent.questions_general_header_contents?.landing_page_button.translations[selectedLanguage].text
              : "LET'S GO!"
          }
          onClick={() => history.push(`/pre-questionnaire/?dentist=${dentistUuid}`)}
          disabled={!textContent.questions_general_header_contents}
        />
      </ContainerBox>
      <LogoContainer>
        <Logo />
      </LogoContainer>
    </BackgroundContainer>
  );
}

const TitleContainer = styled.div`
  margin-bottom: 16px;
`;
const TextBox = styled.div`
  margin: 0 20px 32px 20px;
`;
const LogoContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
`;

export default LandingPage;
