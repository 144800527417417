import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {selectDentistLogoURL} from './questionnaireSlice';

const DentistLogo = () => {
  const logoUrl = useSelector(selectDentistLogoURL);

  if (logoUrl) {
    return (
      <LogoContainer>
        <StyledImage src={logoUrl} />
      </LogoContainer>
    );
  }

  return null;
};

const LogoContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 372px;
`;

const StyledImage = styled.img`
  border-radius: 16px;
  display: block;
  max-height: 200px;
  max-width: 100%;
`;

export default DentistLogo;
