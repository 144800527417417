import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Webcam from 'react-webcam';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getCookieConsentValue} from 'react-cookie-consent';

import IconButton from '../../components/IconButton';
import {
  selectAnswers,
  selectDentistUuid,
  selectSelectedLanguage,
  selectTextContent,
  setImageFromDevice,
  setNewMouthImage,
} from './questionnaireSlice';
import {ReactComponent as CameraMask} from '../../assets/svg/camera_mask.svg';
import BigButton from '../../components/BigButton';

const QuestionnaireWebcam = () => {
  const {trackEvent} = useMatomo();
  const dispatch = useDispatch();
  const history = useHistory();
  const answers = useSelector(selectAnswers);
  const dentistUuid = useSelector(selectDentistUuid);
  const textContent = useSelector(selectTextContent);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const nullAnswers = answers.filter((a: Answer) => a.value === null);

  const [seconds, setSeconds] = React.useState(5);
  const [timerOn, setTimerOn] = React.useState(false);
  const [cameraOpen, setCameraOpen] = React.useState(false);
  const webcamRef = React.useRef(null);

  const fileInputRef = useRef(null);

  const videoConstraints = {
    width: {min: 720},
    height: {min: 720},
    aspectRatio: 1,
  };

  const capture = React.useCallback(() => {
    if (webcamRef && webcamRef.current) {
      // @ts-ignore
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        if (getCookieConsentValue()) {
          trackEvent({category: 'camera', action: 'Initial photo'});
        }
        dispatch(setNewMouthImage(imageSrc));
        setSeconds(5);
        setTimerOn(false);
        dispatch(setImageFromDevice(false));
        history.push(`/questionnaire/?dentist=${dentistUuid}`);
      }
    }
  }, [webcamRef, dispatch, history, trackEvent, dentistUuid]);

  useEffect(() => {
    if (timerOn && seconds > 0) {
      let timer = setTimeout(() => setSeconds(seconds - 1), 1000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      capture();
    }
  }, [seconds, timerOn, capture]);

  const openCameraPressed = () => {
    setCameraOpen(true);
    if (getCookieConsentValue()) {
      trackEvent({category: 'camera', action: 'Initial camera opened'});
    }
  };

  return (
    <StyledWebcamContainer>
      {cameraOpen ? (
        <>
          <PaddedWebcamContainer>
            <WebcamContainer>
              <StyledCameraMask width="100%" height="100%" />
              <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                minScreenshotHeight={1024}
                minScreenshotWidth={1024}
                width="100%"
                audio={false}
                mirrored={true}
              />
            </WebcamContainer>
          </PaddedWebcamContainer>
          <IconButton
            text={
              timerOn
                ? `${
                    textContent.questions_general_header_contents?.pre_questionnaire_page_timer_text.translations[
                      selectedLanguage
                    ].text
                  } ${seconds.toString()}`
                : `${textContent.questions_general_header_contents?.pre_questionnaire_page_start_timer_button.translations[selectedLanguage].text}`
            }
            onClick={() => setTimerOn(true)}
          />
        </>
      ) : (
        <React.Fragment>
          <IconButton
            id="webcam-button"
            text={
              textContent.questions_general_header_contents?.pre_questionnaire_page_open_camera_button.translations[
                selectedLanguage
              ].text
            }
            onClick={() => openCameraPressed()}
            disabled={nullAnswers.length > 0}
          />
        </React.Fragment>
      )}
      {/* Always show the 'Choose from device' button */}
      <React.Fragment>
        <br />
        <label style={{}}>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            style={{display: 'none'}}
            ref={fileInputRef}
            onChange={(e: any) => {
              var reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = function () {
                dispatch(setNewMouthImage(reader.result as string));
                dispatch(setImageFromDevice(true));
                history.push(`/questionnaire/?dentist=${dentistUuid}`);
              };
              reader.onerror = function (error) {
                console.error('Error: ', error);
              };
            }}
          />
          <BigButton
            text={
              textContent.questions_general_header_contents?.pre_questionnaire_page_choose_from_device_button
                .translations[selectedLanguage].text
            }
            onClick={() => {
              if (fileInputRef && fileInputRef.current) {
                (fileInputRef.current as any).click();
              }
            }}
            disabled={nullAnswers.length > 0}
          />
        </label>
      </React.Fragment>
    </StyledWebcamContainer>
  );
};

const StyledWebcamContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const WebcamContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 18px;
  overflow: hidden;
`;

const PaddedWebcamContainer = styled.div`
  margin-left: 0px;
`;
const StyledCameraMask = styled(CameraMask)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;

export default QuestionnaireWebcam;
